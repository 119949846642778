<template>
  <Companies :select-company="selectCompany"/>
</template>

<script>
import { mapGetters } from 'vuex'
import Companies from '../../components/partials/Companies.vue'

export default {
  created() {
    if (!this.isSuperAdmin) {
      this.$router.replace({ name: 'employees.users', params: { companyId: this.company_id } })
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
      company_id: 'auth/company_id',
    }),
  },
  methods: {
    selectCompany(companyId) {
      this.$router.push({
        name: 'employees.users',
        params: {
          companyId,
        },
      })
    },
  },
  components: {
    Companies,
  },
}
</script>
